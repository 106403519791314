:root {
	--background-color: hsl(212, 100%, 96%);
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: var(--background-color);
	font-family: "Anonymous Pro", monospace;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sticky {
	/* border: solid red 3px; */
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 20vw;
	height: 100vh;
	/* border: solid green 3px; */
}
.navbar {
	width: 20vw;
	height: 20vh;
	/* border: solid black 3px; */
	position: fixed;
}
.navbartitle {
	/* border: solid red 3px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 100vw;
	height: 20vh;
	/* border: solid black 3px; */
}
.searchbar {
	margin-right: 3rem;
}
.title {
	margin-left: 4rem;
}

.links,
a {
	text-decoration: none;
	color: black;
}

a:hover {
	color: #eccf6e;
}

.countries {
	overflow: scroll;
}

@media all and (max-width: 768px) {
	.navbar,
	.sticky {
		justify-content: center;
		width: 100%;
		height: 20vh;
		position: static;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.navbartitle {
		justify-content: space-around;
		height: 10vh;
		margin-top: 1rem;
	}
	body {
		font-size: 10px;
	}
	.title {
		margin-left: 0rem;
	}
}

.title-link {
	text-decoration: none;
	color: black;
}
#country-title {
	color: hsl(341, 73%, 72%);
}
#country-title:hover {
	color: black;
}

p {
	margin: 0.5rem;
	font-size: 1rem;
	font-weight: 600;
}
/* //about page  */
.aboutnavtext {
	color: hsl(35, 67%, 96%);
}
.aboutnav {
	padding-top: 2rem;
	display: flex;
	justify-content: center;
	background: hsl(348, 33%, 56%);
	color: hsl(35, 67%, 99%);
}
.aboutus {
	padding-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	background: hsl(348, 33%, 56%);
	color: hsl(35, 67%, 99%);
	width: 100%;
	height: 100vh;
}
.aboutbrand {
	padding-top: 2rem;
	display: flex;
	overflow: auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	background: hsl(348, 33%, 56%);
	color: hsl(35, 67%, 99%);
	width: 100%;
	font-size: 1.4rem;
}
.howtouse {
	padding-top: 2rem;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	background: hsl(35, 67%, 99%);
	color: hsl(348, 33%, 56%);
	max-width: 100vw;
	height: 70vh;
	overflow: scroll;
}
.howtousetitle {
	padding-top: 2rem;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	background: hsl(35, 67%, 99%);
	color: hsl(348, 33%, 56%);
	width: 100%;
	height: 10vh;
}

.howtocard {
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	border: solid 1px hsl(348, 33%, 56%);
	max-width: 20vw;
	max-height: 30vh;
	padding: 3rem;
	margin: 2rem;
}
.howtocard::first-letter {
	border-bottom: solid 2px hsl(348, 33%, 56%);
}
.textabout {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	background: hsl(348, 33%, 56%);
	color: hsl(35, 67%, 99%);
	/* border: solid hsl(40, 48%, 48%) 2px; */
	width: 48%;
	font-size: 28px;
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
}

.teamimg {
	max-width: 55vw;
	max-height: 80vh;
}

.aboutusheading {
	font-size: 10rem;
	margin-left: 5rem;
}

p {
	text-align: center;
}

@media all and (max-width: 900px) {
	p {
		font-size: 1.7vw;
	}
	.aboutbrand,
	.aboutbrand2 {
		flex-direction: column;
		overflow: hidden;
		flex-wrap: wrap;
	}
	.aboutus {
		font-size: 6rem;
	}

	.howtocard {
		padding: 0.1rem;
		max-height: 10vh;
		font-size: 0.5rem;
	}
	.aboutus {
		font-size: 6vw;
	}
}
/* admin form */

#submitadmin {
	font-size: 1.2vw;
}

@media all and (max-width: 600px) {
	.navbartitle {
		flex-direction: column;
		justify-content: center;
		height: 15vh;
		margin-top: 1rem;
		flex-wrap: nowrap;
	}
	.searchbar {
		margin-top: 1rem;
		margin-right: 0;
	}
}
